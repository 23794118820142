<template>
  <div class="container py-5 px-0 animate__animated animate__fadeIn">
    <h3 class="mb-4 text-center">Ingresa la Contraseña</h3>
    <p :class="noPasswordValidDisplay" class="text-center text-danger">Contraseña no válida.</p> 
    <input type="password" v-model="password" ref="password" v-on:keyup.enter="nextStep()" class="form-control mb-3" placeholder="Password">
    <button class="btn btn-primary btn-block mb-3" v-on:click="nextStep()">Siguiente</button>
  </div>
</template>

<script>
import axios from 'axios'
import current from '@/logic/settings.js'

export default {
  name: 'LoginPassword',
  data() {
    return {
      password: '',
      noPasswordValidDisplay: ''
    }
  },
  methods: {
    nextStep() {
      var headers = {
        'Access-Control-Allow-Origin' : '*',
        'Access-Control-Allow-Methods':'GET',
        "Access-Control-Allow-Headers": "Origin, Content-Type",
        "Content-Type": "application/json",
      }
      var data = {
        username: this.$store.getters.getLogin.username,
        method: 'password',
        data: this.password
      }

      axios.post(current.api().endpoint + current.api().version + '/login', data, { headers }).then( (result) => {
        var resultData = result.data.data
        
        if(resultData != "Password Invalid.") {
          
          var jwt = atob(resultData)
          
          var jwtData = jwt.split(".")
          jwtData = jwtData[1]
          jwtData = JSON.parse(atob(jwtData))
          
          var session = { id: jwtData.user_id, token: btoa(jwt) }
          session = btoa(JSON.stringify(session))
          
          this.$cookies.set("__xor_user", session)
          this.$store.commit("setCurrentStep", "clogged")

        } else this.noPasswordValidDisplay = 'd-block' 
        
      })
    },
    focusInput() {
      this.$refs.password.focus();
    }
  },
  mounted() {
    this.focusInput()
    this.noPasswordValidDisplay = 'd-none'
  },
}
</script>